import styled from "styled-components"

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  .MuiInputBase-root,
  .MuiSelect-root {
    width: 700px !important;
    max-width: 80vw !important;
    padding-right: 0;
  }

  .MuiInputBase-root {
    background-color: white;
    margin-bottom: 20px;
  }

  button {
    margin-top: 20px;
  }
`
