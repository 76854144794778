import React, { useState } from "react"
import {
  Button,
  FormControl,
  TextField,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core"

import { Form } from "./styles"

const options = [
  "Quero um novo site",
  "Quero melhorar meu site",
  "Quero vender produtos na internet",
  "Quero um sistema web",
  "Quero um aplicativo para celular",
  "Quero fazer integração entre sistemas",
  "Outros",
]

const optionsFuncionarios = [
  "Nenhum",
  "Entre 1 e 2",
  "Entre 3 e 10",
  "Entre 11 e 50",
  "Acima de 50",
]

interface ContactFormProps { }

const ContactForm: React.FC<ContactFormProps> = ({ ...rest }) => {
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [assunto, setAssunto] = useState("")
  const [funcionarios, setFuncionarios] = useState("")
  const [mensagem, setMensagem] = useState("")

  return (
    <Form method="post" netlify-honeypot="bot-field" data-netlify="true" action="/contato-sucesso" name="contact">
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="F-name" value="contact" />
      <TextField
        value={name}
        label="Nome"
        variant="filled"
        name="nome"
        onChange={event => setName(event.target.value)}
        required
      />
      <TextField
        value={email}
        label="Email"
        variant="filled"
        name="email"
        onChange={event => setEmail(event.target.value)}
        required
      />
      <TextField
        value={phone}
        label="Telefone"
        variant="filled"
        name="telefone"
        onChange={event => setPhone(event.target.value)}
        required
      />
      <FormControl variant="filled">
        <InputLabel>Assunto*</InputLabel>
        <Select
          value={assunto}
          name="assunto"
          onChange={event => setAssunto(event.target.value as string)}
          required
        >
          <MenuItem value="">---</MenuItem>
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="filled">
        <InputLabel>Quantos funcionários sua empresa tem?*</InputLabel>
        <Select
          value={funcionarios}
          name="funcionarios"
          onChange={event => setFuncionarios(event.target.value as string)}
          required
        >
          <MenuItem value="">---</MenuItem>
          {optionsFuncionarios.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        value={mensagem}
        multiline
        label="Mensagem"
        variant="filled"
        rows={10}
        name="mensagem"
        onChange={event => setMensagem(event.target.value)}
      />
      <Button size="large" variant="contained" color="primary" type="submit">
        ENVIAR
      </Button>
    </Form>
  )
}

export default ContactForm
