import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

import myTheme from "../../../theme"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 1200px;
  margin: auto;
  padding: 40px 30px;
  overflow: hidden;

  div:first-child {
    width: 350px;

    button {
      width: 250px;
      margin-bottom: 20px;
      padding: 10px;

      svg {
        margin-right: 6px;
      }
    }
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  h2 {
    color: ${props => props.color};
    font-size: 42px;
    line-height: 1.2em;

    .primary {
      color: ${myTheme.colors.primary};
    }
  }

  @media (max-width: ${myTheme.responsive.container}) {
    padding: 60px 0;

    div:first-child {
      width: 100%;
    }

    button {
      margin-right: 10px;
    }

    h2 {
      font-size: 32px;
      line-height: 1.2em;
      margin-top: 0;
    }
  }
`

export const Background = styled(BackgroundImage)`
  min-height: 480px;
  display: flex;
  padding: 0 30px;

  justify-content: center;
`

interface NormalBackgroundProps {
  image: string;
}

export const NormalBackground = styled.div<NormalBackgroundProps>`
  height: 480px;
  display: flex;
  padding: 0 30px;
  background-image: url(${(props) => props.image});
  background-position: top center;

  justify-content: center;

  @media(max-width: ${myTheme.responsive.container}){
    height: 100vh;
    max-height: 700px;
  }
`

interface IBackgroundColorProps {
  bgColor: string
}

export const BackgroundColor = styled.div<IBackgroundColorProps>`
  min-height: 480px;
  display: flex;
  padding: 0 30px;
  background-color: ${props => props.bgColor};

  justify-content: center;
`

export const BackgroundOverlay = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;

  background-color: rgba(0, 0, 0, 0.7);
  background-repeat: repeat;
  background-size: auto;
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
`
