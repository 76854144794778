import React from "react"
import { Button } from "@material-ui/core"
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa"

import {
  Container,
  Background,
  BackgroundOverlay,
  BackgroundColor,
  NormalBackground
} from "./styles"
import ContactForm from "../../Molecules/ContactForm"

interface ContactSectionProps {
  fluidImage?: any
  color?: string
  bgColor?: string
  contactButtonColor?: "primary" | "secondary" | "default"
  whatsappNumberLink?: string
  phoneNumberLink?: string
  id?: string;
}

const ContactSection: React.FC<ContactSectionProps> = ({
  fluidImage,
  color = "white",
  bgColor,
  contactButtonColor = "secondary",
  whatsappNumberLink,
  phoneNumberLink,
  ...rest
}) => {
  const ElementBackground = fluidImage
    ? ({ children, ...rest }) => {
      return typeof fluidImage === 'string' 
        ? <NormalBackground image={fluidImage} { ...rest }>{children}</NormalBackground> 
        : <Background fluid={fluidImage} {...rest }>{children}</Background>
    }
    : ({ children }) => (
        <BackgroundColor bgColor={bgColor}>{children}</BackgroundColor>
      )
  return (
    <ElementBackground>
      <BackgroundOverlay />
      <Container color={color} {...rest}>
        <div>
          <h2>
            Fale com a gente sobre seu projeto<span className="primary">.</span>
          </h2>
          <h2>Estamos prontos a te ajudar.</h2>
          {whatsappNumberLink && (
            <a href={whatsappNumberLink}>
              <Button variant="outlined" color={contactButtonColor} 
          data-sal="slide-right"
          data-sal-easing="ease"
          data-sal-duration="1000">
                <FaWhatsapp size={20} />
                Chamar no WhatsApp
              </Button>
            </a>
          )}
          {phoneNumberLink && (
            <a href={phoneNumberLink}>
              <Button variant="outlined" color={contactButtonColor} 
          data-sal="slide-right"
          data-sal-easing="ease"
          data-sal-duration="1000">
                <FaPhoneAlt size={16} />
                Ligar para Consultor
              </Button>
            </a>
          )}
        </div>
        <div>
          <h2>Ou, preencha o formulário abaixo:</h2>
          <ContactForm />
        </div>
      </Container>
    </ElementBackground>
  )
}

export default ContactSection
